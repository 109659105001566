<template>
  <div v-if="selectedNomIsEmpty">Sélectionnes une nomenclature !</div>
  <loader v-else-if="loading" />
  <div v-else class="geographic">
    <div class="title">{{ $t('forecasts.title') }}</div>
    <div class="chips">
      <div class="info">{{ $t('forecasts.chips.overview') }}</div>
      <div v-for="overview in overviews"
           :key="overview"
           :class="[{ active: currentOverview === overview }, 'chip', overview]"
           @click="currentOverview = overview">
        {{ $t(`forecasts.overview.${ overview }`) }}
      </div>
    </div>
    <div class="geographic-array">
      <div class="th nom">
        <div class="nom-label">{{ `${nomenclature[0][`${ nomenclature[0].nomLevel }Label`]}` }}</div>
        <div class="search">
          <v-svg h="1.2rem" name="loupe" />
          <input v-model="searchTerm"
                 type="text"
                 class="input"
                 :placeholder="$t('search')">
        </div>
        <div class="favorites-filter">
          <v-svg v-for="(type, i) in favTypes"
                 :key="`fav-filter-${i}`"
                 :class="{ active: activeFavoriteTypes.includes(type) }"
                 h="1.2rem"
                 :name="activeFavoriteTypes.includes(type) ? `${type}-active` : type"
                 @click="onFavoriteFilterClicked(type)" />
        </div>
      </div>
      <div v-for="day in daysInPeriod" :key="day" class="th day">{{ day }}</div>
      <div class="th action">Actions</div>
      <template v-for="(item, i) in searchFilteredRows" :key="i">
        <div :class="['td nom']">
          <v-svg :key="i"
                 h="1rem"
                 :name="getFavoriteTypeOfItem('geography', item) ? `${getFavoriteTypeOfItem('geography', item)}-active` : 'star'"
                 @click="addOrUpdateFavorite('geography', { site: item.site, siteLabel: item.siteLabel, siteSid: item.siteSid })" />
          <div class="label">
            {{ item.site }} - {{ item.siteLabel }}
          </div>
        </div>
        <template v-for="(forecast, j) in item.days" :key="j">
          <div :class="[buildColorClassIndicator(forecast), 'td']">
            <span v-if="!loadingSiteRow[item?.site]">
              {{ $filters.formatNumber(buildDisplayIndicator(forecast[buildAttributeName], currentOverview), buildNumberOfDecimalIndicator(currentOverview), buildUnitIndicator(currentOverview), currentOverview === 'evolutionPercentSalesQuantity') }}
            </span>
            <span v-else class="skeleton" />
          </div>
        </template>
        <div class="td action">
          <template v-if="nomenclature[0].nomLevel === 'category'">
            <v-svg h="1rem" name="eye" @click="onShowMore({ index: i })" />
            <template v-if="$filters.userIsReam() || $filters.userIsAdmin()">
              <v-svg h="1rem" name="pencil" @click="onRowEdit({ index: i })" />
              <v-svg v-if="item.isPadlock" h="1rem" name="lock" />
            </template>
          </template>
          <template v-else>
            <v-svg v-if="item.isPadlock" h="1rem" name="lock" />
          </template>
        </div>

        <row-details v-if="detailedRows[i]" level="parent" :days="item.days" />

        <row-edition v-if="editingForecast[i]"
                     level="parent"
                     :days="item.days"
                     :overview="currentOverview"
                     :category="nomenclature[0]"
                     :geography-id="item?.site"
                     @fetchItemRow="fetchSitesRows(item?.site, i)" />
      </template>
    </div>
  </div>
</template>

<script>
  import { DateTime } from 'luxon'
  import { computed, inject, reactive, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useToast } from 'vue-toastification'
  import { extendWithAuthorization, http } from '@/http'
  import RowDetails from '@/components/nomenclature/RowDetails'
  import RowEdition from '@/components/nomenclature/RowEdition.vue'
  import useContextStore from '@/store/context.store'
  import { overviews, favoritesTypes } from '@/utils/CONF'
  import {
    buildColorClassIndicator,
    buildDisplayIndicator,
    buildNumberOfDecimalIndicator,
    buildUnitIndicator,
    removeAccents
  } from '@/utils/indicators.utils'

  export default {
    name: 'Nomenclature',
    components: { RowDetails, RowEdition },

    setup() {
      const toast = useToast()

      const contextStore = useContextStore()
      const { nomenclature, getPeriodStartDateEndDate, period, getFavoriteTypeOfItem, addOrUpdateFavorite, getFavoritesByTypes } = contextStore

      const daysInPeriod = computed(() => {
        const date = DateTime.fromObject({ weekYear: period.value.year, weekNumber: period.value.weekNumber })
        return Array(7).fill().map((_, i) => date.startOf('week').plus({ day: i }).toFormat('ccc dd/LL'))
      })
      const currentOverview = ref(overviews[0])
      const buildAttributeName = computed(() => currentOverview.value.concat('GCH'))

      const editingForecast = reactive({})
      const rows = ref([])

      const onRowEdit = ({ index }) => {
        editingForecast[index] = editingForecast[index] ? !editingForecast[index] : true
      }

      const loading = ref(false)
      const loadingSiteRow = ref({})

      const fetchSitesRows = async(site, index) => {
        if (nomenclature.value?.length) {
          if (site) {
            editingForecast[index] = false
            loadingSiteRow.value[site] = true
          }
          else loading.value = true
          const extendedHttp = await extendWithAuthorization(http)
          await extendedHttp
            .get(`indicators/geographic/${ nomenclature.value[0].nomLevel }/${ nomenclature.value[0][nomenclature.value[0].nomLevel] }`, { searchParams: { ...getPeriodStartDateEndDate.value, site } })
            .json()
            .then(res => {
              if(site) {
                toast.success("Mise à jour effectuée avec succès")
                rows.value[index] = res[0]
              } else rows.value = res
            })
            .catch(err => {
              toast.error('Une erreur est survenue lors du chargement des sites')
              console.error(err)
            })
          loading.value = false
          loadingSiteRow.value[site] = !loadingSiteRow.value[site]
        }
      }

      const emitter = inject("emitter")
      emitter.on('reloadData', () => fetchSitesRows())

      const searchTerm = ref(undefined)

      const searchFilteredRows = computed(() => {
        if (!searchTerm.value?.length) return favoriteFilteredRows.value
        return favoriteFilteredRows.value.slice().filter(row => removeAccents(`${ row.site } - ${ row.siteLabel }`).includes(removeAccents(searchTerm.value)))
      })

      const activeFavoriteTypes = ref([])

      const favTypes = ref(favoritesTypes)

      const favoriteFilteredRows = computed(() => {
        if (!activeFavoriteTypes.value.length) return rows.value
        return rows.value.slice().filter(row => getFavoritesByTypes('geography', activeFavoriteTypes.value).some(favoriteItem => parseInt(favoriteItem.site) === parseInt(row.site)))
      })

      const onFavoriteFilterClicked = type => {
        const isAlreadySelectedIndex = activeFavoriteTypes.value.findIndex(activeType => activeType === type)
        isAlreadySelectedIndex > -1 ? activeFavoriteTypes.value.splice(isAlreadySelectedIndex, 1) : activeFavoriteTypes.value.push(type)
      }

      const selectedNomIsEmpty = computed(() => !nomenclature.value?.length)

      const route = useRoute()

      watch(() => ({ ...route.query }), () => {
        if (route.name === 'Geographic') fetchSitesRows()
      })

      const detailedRows = reactive({})

      const onShowMore = ({ index }) => detailedRows[index] = detailedRows[index] ? !detailedRows[index] : true

      if (nomenclature.value?.length) fetchSitesRows()

      return {
        searchFilteredRows,
        searchTerm,
        editingForecast,
        onRowEdit,
        rows,
        fetchSitesRows,
        loadingSiteRow,
        daysInPeriod,
        overviews,
        currentOverview,
        buildColorClassIndicator,
        buildAttributeName,
        buildDisplayIndicator,
        buildNumberOfDecimalIndicator,
        buildUnitIndicator,
        selectedNomIsEmpty,
        nomenclature,
        loading,
        getFavoriteTypeOfItem,
        addOrUpdateFavorite,
        activeFavoriteTypes,
        onFavoriteFilterClicked,
        favTypes,
        onShowMore,
        detailedRows
      }
    }
  }
</script>

<style scoped lang="scss">
  .geographic {
    width: 100%;
    display: flex;
    flex-direction: column;
    @extend %secondary-font--bold;
    font-size: $font-size-small;
    border: 1px solid #A6A6A6;
    border-radius: 16px;
    position: relative;
    margin: 1rem 0;

    .title {
      @extend %primary-font--bold;
      font-size: $font-size-extra-big;
      position: absolute;
      top: -27px;
      left: 1rem;
      z-index: 11;
      background-color: white;
      padding: 0 0.5rem;
    }

    .chips {
      position: absolute;
      top: -10px;
      right: 1rem;
      z-index: 11;
      background-color: white;
      padding: 0 0.5rem;
      display: flex;
      align-items: center;

      .info {
        color: #A6A6A6;
        @extend %primary-font;
      }

      .chip {
        border: 1px solid var(--app-color);
        border-radius: 13px;
        color: var(--app-color);
        background-color: white;
        padding: 1px 7px;
        margin-left: 0.3rem;
        cursor: pointer;

        &.active {
          background-color: var(--app-color);
          color: white;
        }

        &.input {
          border: none;
          background-color: rgba(64, 64, 64, 1);
          color: white;
        }
      }
    }

    .geographic-array {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 40% 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      row-gap: 0.5rem;
      padding: 2rem 1rem 1rem 1rem;

      .th {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #404040;
        padding-bottom: 0.5rem;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 12;
        background-color: white;
        @extend %primary-font--bold;
        font-size: $font-size-small;

        &.action { justify-content: center; }

        &.day {
          flex-direction: column;
          align-items: center;
          word-spacing: 10000000000000000000px;
          text-align: center;
          text-transform: capitalize;
        }

        &.nom {
          .nom-label {
            display: flex;
            align-items: center;
            color: var(--app-color);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-right: 1rem;
          }

          .v-svg { color: #808080; }

          .search {
            display: flex;
            align-items: center;
            margin: 0 0.5rem 0 auto;
            border-right: 2px solid #A6A6A6;

            .svg-loupe { color: #808080; }

            input[type='text'] {
              margin-left: 0.5rem;
              width: 100px;
              border: none;
              outline: none;
              font-size: $font-size-small;
              @extend %secondary-font--bold;
              color: #808080;
            }
          }

          .favorites-filter {
            display: flex;
            align-items: center;
          }
        }
      }

      .td {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EFE3DB;
        padding: 4px 0;
        z-index: 9;

        &.nom { border-radius: 10px 0 0 10px; }

        &.action, &.blank {
          border-radius: 0 10px 10px 0;

          .svg-pencil, .svg-eye {
            cursor: pointer;
            color: #808080;
            margin-right: 0.5rem;
          }
        }

        &.double-restocking {
          font-weight: bold;
          color: var(--color-double-restocking);
        }

        &.user-value {
          font-weight: bold;
          color: var(--color-user-value);
        }

        &.multiplier {
          font-weight: bold;
          color: var(--color-multiplier);
        }

        &.limited {
          font-weight: bold;
          color: var(--color-limited);
        }

        &.forced {
          font-weight: bold;
          color: var(--color-forced);
        }

        .skeleton{
          border-radius: 7px;
          width: 30px;
          height: 18px;
          background-image: linear-gradient(90deg, #9E512147 0px, #9E512129 40px, #9E512147 80px);
          animation: shine-lines 1.6s infinite;

          @keyframes shine-lines {
            0% {
              background-position: -100px
            }
            40%, 100% {
              background-position: 140px
            }
          }
        }
      }

      .nom {
        justify-content: start;
        padding-left: 1rem;
        user-select: none;

        .v-svg {
          cursor: pointer;
          margin-right: 0.5rem;
        }

        .svg-spinner {
          margin: 0 0 0 0.2rem;
        }

        .svg-arrow-up { margin: -12px 0 0 0.3rem; }

        &.opened {
          .svg-arrow-up {
            transform: rotateX(180deg);
            margin: 10px 0 0 0.3rem;
          }
        }
      }

      .row-edition, .row-details {
        grid-column-start: 1;
        grid-column-end: -1;
      }

      .carriage {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .childs, .grand-childs {
        grid-column-start: 1;
        grid-column-end: -1;
        display: grid;
        row-gap: 0.5rem;
      }

      .childs {
        grid-template-columns: 20px 20px calc(40% - 40px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        .td { background-color: #DBDBDB; }
      }

      .grand-childs {
        grid-template-columns: 20px 20px 20px 20px calc(40% - 80px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        .td { background-color: #EBEBEB; }
      }
    }
  }
</style>
